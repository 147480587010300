import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'se-multiSelect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectComponent {
  @Input() dropdownValues: any[];
  @Input() preSelected: string[];
  @Input() isDisabled: boolean;

  @Output() changedSelection = new EventEmitter<any>();

  selectedValues: string[];

  ngOnInit() {
    // Transform dropdownValues to only contain the value strings
    this.dropdownValues = this.dropdownValues.map((item) => item.value);

    this.selectedValues = this.preSelected;
    if (this.preSelected) {
      this.selectedValues = this.dropdownValues.filter((item) =>
        this.preSelected.includes(item)
      );
    }
  }

  /**
   * Emits the current selection of values.
   */
  saveSelection() {
    const transformedValues =
      this.selectedValues.length === 0 ? [''] : this.selectedValues;
    this.changedSelection.emit(transformedValues);
  }

  /**
   * Clears the current selection and emits the change.
   */
  clearSelection() {
    this.selectedValues = [''];
    this.saveSelection();
  }

  getSelectedValues(): string {
    return this.selectedValues.map((value) => value).join(', ');
  }
}
