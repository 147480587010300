<div class="container">
  <p *ngIf="loading">Loading...</p>
  <p *ngIf="!loading && bulkEditHistory.length === 0">
    No bulk edits to show in the last 24h.
  </p>
  <p-table
    *ngIf="!loading && bulkEditHistory.length > 0"
    [value]="bulkEditHistory"
    showGridlines
    stripedRows
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="RISK_DB_KEY">Risk Column</th>
        <th pSortableColumn="RISK_DB_VALUE">Risk Value</th>
        <th>Status</th>
        <th>User</th>
        <th>Time</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-edit>
      <tr>
        <td>{{ edit.RISK_DB_KEY }}</td>
        <td>{{ edit.RISK_VALUE }}</td>
        <td>
          <p-tag [value]="edit.STATUS" [severity]="getSeverity(edit.STATUS)" />
        </td>
        <td>{{ edit.USER_GID }}</td>
        <td>{{ edit.IMPORT_TIMESTAMP | date : "short" }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
