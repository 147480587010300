/**
 * @Component
 * Main application component displaying the app title, logo, header/footer links, and version info.
 *
 * - Imports:
 *   - Configuration values: appTitle, headerLogo, headerLinks, footerLinks
 *   - Services: MessageService for UI messages, VersionService for application version
 *
 * - Properties:
 *   - title: Application title
 *   - headerLogo: Logo URL
 *   - headerLinks, footerLinks: Navigation links
 *   - version$: Observable for app version
 *
 * - Methods:
 *   - show(): Displays a success message using MessageService
 */

import { Component, OnInit } from '@angular/core';

import {
  appTitle,
  headerLogo,
  headerLinks,
  footerLinks,
} from '@environments/app-config';

import { MessageService } from 'primeng/api';
import { VersionService } from '@core/services/VersioningService';
import { Subscription } from 'rxjs';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  title = appTitle;
  headerLogo = headerLogo;
  private subscription: Subscription;
  footerLinks = footerLinks;
  headerLinks = headerLinks;
  version$ = this.versionService.getVersion();

  protected readonly faCircleInfo = faSyncAlt;

  constructor(
    private messageService: MessageService,
    private versionService: VersionService
  ) {}

  ngOnInit() {
    this.subscription = this.versionService.infoTooltipMessage$.subscribe(
      (messages) => {
        this.messageService.add({
          severity: messages.severity || 'info',
          summary: messages?.summary,
          detail: messages.detail,
          life: messages.life || 10000,
          sticky: messages?.sticky,
        });
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
