<p-multiSelect
  #semultiSelect
  [options]="dropdownValues"
  appendTo="body"
  [(ngModel)]="selectedValues"
  [style]="{ width: '100%' }"
  [panelStyle]="{ width: '300px' }"
  [scrollHeight]="'200px'"
  class="se-multiselect"
  [disabled]="isDisabled"
  [title]="getSelectedValues()"
>
  >
  <ng-template let-selectedItems pTemplate="selectedItems">
    <span *ngIf="selectedItems && selectedItems.length; else emptyLabel">
      {{ selectedItems.length }} item(s) selected
    </span>
    <ng-template #emptyLabel>
      <span>&nbsp;</span>
      <!-- Display a blank space instead of "empty" -->
    </ng-template>
  </ng-template>
  <p-footer>
    <button
      class="se-button btn btn-sm se-button--secondary"
      (click)="clearSelection(); semultiSelect.hide()"
    >
      <span for="clear-option">Clear</span>
    </button>
    <button
      class="se-button btn btn-sm se-button--primary"
      (click)="saveSelection(); semultiSelect.hide()"
    >
      <span for="saving-option">Save</span>
    </button>
  </p-footer>
</p-multiSelect>
