<se-table
  [tableHeaders]="riskTableHeaders"
  [tableHeadersGroup]="riskTableHeadersGroup"
  [tableData]="riskTableData"
  [tableConfig]="riskTableConfig"
  [tableFilters]="riskTableFilters"
  [tableDropdowns]="riskTableDropdowns"
  [totalActionPlans]="totalActionPlans"
  [dataLoading]="dataLoading"
  [valuesFiltered]="filter"
  [tableTotalValues]="totalRisks"
  [bulkEditPermissions]="bulkEditPermissions"
  [bulkEditColumnUpdating]="bulkEditColumnUpdating"
  (fetchRelevantFilters)="fetchRelevantFilters($event)"
  (eventActions)="handleRowAction($event)"
  (eventFilters)="updateFilter($event)"
  (updateRow)="updateField($event)"
  (updateColumn)="updateColumn($event)"
  (loadMore)="getRiskTableDataAndTotals(true)"
  (undoBulkEdit)="undoBulkEdit($event)"
  (bulkEditHistory)="showBulkEditHistoryDialog($event)"
></se-table>
