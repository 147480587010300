<p-toast #toast>
  <ng-template let-message pTemplate="message">
    <div
      class="flex gap-3"
      style="display: flex; justify-content: space-between"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: start;
          flex: 1;
        "
      >
        <div>{{ message.summary }}</div>
        <div>{{ message.detail }}</div>
      </div>
      <button *ngIf="message.sticky" class="refresh-btn" (click)="reloadPage()">
        Refresh <fa-icon [icon]="faCircleInfo" />
      </button>
    </div>
  </ng-template>
</p-toast>

<app-navbar-primary
  [logoUrl]="headerLogo"
  [appTitle]="title"
  [headerLinks]="headerLinks"
></app-navbar-primary>
<div class="content">
  <router-outlet></router-outlet>
</div>
<app-footer [links]="footerLinks" [version]="'v' + (version$ | async)">
</app-footer>
