<div class="comment-dialog">
  <div *ngIf="currentValue">
    <label>Initial value:</label>
    {{ initialValue }}
  </div>

  <div class="comment-dialog__field">
    <label>New value:</label>
    <textarea
      [(ngModel)]="currentValue"
      rows="4"
      cols="50"
      [maxlength]="2000"
    ></textarea>
    <p *ngIf="currentValue.length >= 2000">Maxim length reached</p>
  </div>

  <div class="comment-dialog__buttons">
    <button
      type="submit"
      class="se-button se-button--primary btn"
      [disabled]="initialValue === currentValue"
      (click)="saveComment()"
    >
      <span>Save</span>
    </button>
  </div>
</div>
