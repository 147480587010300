import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'se-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent {
  @Input() date: Date;
  @Input() isDisabled: boolean;
  @Input() defaultDate: Date | null;
  @Input() isInvalid: boolean = false;
  @Output() changedDate: EventEmitter<Date> = new EventEmitter<Date>();
  faCalendar = faCalendar;

  constructor() {}

  onDateChange(date: Date) {
    this.date = date ?? this.defaultDate;
    this.changedDate.emit(this.date);
  }
}
